<template>
  <div class="index">
    <div class="header1">
      <div class="header-l" @click="goTo()">
        <!-- <img src="../../assets/image/logo.png" alt> -->
         <img src="../../assets/icon/ad.png" alt="" @click="$router.push('/')">
        <img src="../../assets/icon/shuxian1.png" alt="" style="margin:0 20px">
        <img src="../../assets/icon/xiehui.png" alt="">
      </div>
      <!-- <div class="header-c">
        <p class="p1">中国广告协会战略支持平台</p>
        <p>中国全渠道广告推广制作平台</p>
      </div> -->
      <div class="header-r">
        <span>已有账号?</span>
        <a @click="login">立即登录</a>
      </div>
    </div>
    <!--  以上是头部     -->
    <div class="line"></div>
    <div class="main">
      <div class="div1">
        <span class="span1">欢迎注册</span>
      </div>
      <div class="div2">
        <input
          type="text"
          placeholder="手机号"
          @blur="phoneBlur"
          @focus="flagPhone = false"
          v-model="phone"
          class="f15"
          maxlength="11"
        >
        <!-- <div class="tishi">
          <div class="tishi-l" :class="[flagPhone ? 'error':'']">*</div>
          <div class="tishi-r" :class="[flagPhone ? 'error':'']">11位手机号码</div>
        </div> -->
      </div>
      <div :class="[flagPhone ? 'error-b':'correct1']">请输入11位有效手机号码！</div>

      <div class="div3" v-if="flag">
        <div class="div3-1" @click="verification">点击此处进行验证</div>
        <div class="div3-2">
          <div class="div3-2-l">*</div>
          <div class="div3-2-r">点击进行验证</div>
        </div>
      </div>
      <div class="div3-y" v-if="flag1">
        <input type="text" v-model="code" placeholder="输入验证码" class="teshu" style="font-size:15px">
        <div class="time" @click="verification">{{seconds > 0 ? seconds + 's' : '验证码'}}</div>
      </div>

      <div class="div2 mima">
        <input
          placeholder="设置密码"
          type="password"
          @focus="pwdError = false,confirmPwdError = false"
          @blur="passwordBlur(1)"
          v-model="password"
          maxlength="16"
          style="font-size:15px"
        >
        <!-- <div class="tishi">
          <div class="tishi-l" :class="[pwdError ? 'error':'']">*</div>
          <div class="tishi-r" :class="[pwdError ? 'error':'']">密码长度8-16位，数字、字母、字符至少包含两种</div>
        </div> -->
        <!-- <p class="p1">建议使用字母、数字和符号两种以上组合，6-20个字符</p> -->
      </div>
      <div :class="[pwdError ? 'error-b':'correct2']">密码长度8-16位，数字、字母、字符至少包含两种</div>

      <div class="div2 mima mima2">
        <input
          type="password"
          placeholder="确认密码"
          @blur="passwordBlur(2)"
          v-model="confirmPwd"
          maxlength="16"
          @focus="confirmPwdError = false"
          style="font-size:15px"
        >
        <!-- <div class="tishi">
          <div class="tishi-l" :class="[confirmPwdError ? 'error':'']">*</div>
          <div class="tishi-r" :class="[confirmPwdError ? 'error':'']">再次输入密码，确保两次一致</div>
        </div> -->
      </div>
      <div :class="[confirmPwdError ? 'error-c':'correct3']">再次输入密码，确保两次一致</div>

      <div class="div4">
        <input type="checkbox" class="ipt1" id="checkbox" :checked="agree" @change="agreeHandler">
        <label for="checkbox" class="lab1"></label>
        <div class="div4-2">
          <span class="span1">我已阅读并同意</span>
          <span class="span2">《孔有礼使用协议》</span>
        </div>
      </div>
      <button class="regist" :class="[agree ? '': 'grey']" @click="register">立即注册</button>
    </div>
  </div>
</template>


<script>
export default {
  name: 'index',
  components: {},
  data() {
    return {
      flag: true,
      flag1: false,
      seconds: 0,
      agree: false,
      flagVerification: false, //验证码提示隐藏
      phone: '',
      flagPhone: false, //手机号错误提示显示隐藏
      password: '',
      confirmPwd: '',
      code: '', //验证码
      pwdError: false, //设置密码错误提示显示隐藏
      confirmPwdError: false //确认·密码错误提示显示隐藏
    };
  },
  methods: {
    //事件处理对象
    phoneBlur() {
      //注册手机号正则验证
      if (!this.$check.phone(this.phone)) {
        this.flagPhone = true;
      } else {
        this.flagPhone = false;
      }
    },
    passwordBlur(flag) {
      //设置密码错误正则验证
      if (flag === 1) {
        if (!this.$check.password(this.$check.trim(this.password))) {
          this.pwdError = true;
        } else {
          this.pwdError = false;
        }
      }
      else{
        //确认密码错误正则验证
        if (this.confirmPwd != this.password) {
        //  console.log(this.confirmPwd,this.password)
          this.confirmPwdError = true;
        }
      }
    },
    /**
     * @description: 获取验证码
     * @param {type}
     * @return:
     */
    verification(){
        let captcha1 = new TencentCaptcha('2083566032', (res=>{
            if (res.ret===0){
                if (!this.$check.phone(this.phone)) {
                    this.flagPhone = true;
                    return;
                }
                if (this.seconds > 0) return;
                this.$http.get(this.$API.GETCODE, { params: { mobile: this.phone } }).then(res => {
                    if (res.data.code === 0) {
                        this.$notify({
                            title: '成功',
                            message: '验证码发送成功!',
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            title: '提示',
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    //点击验证
                    this.seconds = 59;
                    this.flag = false;
                    this.flag1 = true;
                    let timer = setInterval(() => {
                        //60秒倒计时
                        this.seconds--;
                        if (this.seconds === 0) {
                            clearInterval(timer);
                            timer = null;
                        }
                    }, 1000);
                });
            }
        }));
        captcha1.show(); // 显示验证码
    },
    /**
     * @description: 调用注册接口
     * @param {type}
     * @return:
     */
    register() {
      if (this.flagPhone || this.pwdError || this.confirmPwdError || this.code === '') return;
      const param = {
        verificationCode: this.code,
        phoneNumber: this.phone,
        password: this.password,
        confirmPassword: this.confirmPwd
        // password: this.$utils.encrypt(this.password.toUpperCase()),
        // confirmPassword: this.$utils.encrypt(this.confirmPwd.toUpperCase())
      };
      this.$http.post(this.$API.REGISTER, param).then(res => {
        this.phone = ''
        this.password = ''
        this.confirmPwd = ''
        this.code = ''
        this.seconds = 0
        this.flag = true
        this.flag1 = false
        this.$router.push({ path: '/login' });
      });
    },
    /**
     * @description: 跳转登录页面
     * @param {type}
     * @return:
     */
    login() {
      this.$router.push('/login');
    },
    agreeHandler() {
      this.agree = !this.agree;
    },
    goTo () {
      this.$router.push('/')
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/common.scss';
@mixin icon($m) {
  $url: '../../assets/image/' + $m;
  @include bgImage($url);
  background-size: contain;
}
.index {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .header1 {
    width: 1200px;
    height: 149px;
    margin: 0 auto;
    border-bottom: 1px solid #d82229;
    > div {
      float: left;
    }
    .header-l {
        width: 404px;
        height: 149px;
            display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
        img {
            display: block;
            width: 100%;
            height: 58px;
        }
    }
    .header-c {
      margin-left: 23px;
      p {
        height: 27px;
        font-size: 16px;
        color: #333333;
      }
      .p1 {
        margin-top: 46px;
      }
    }
    .header-r {
      width: 175px;
      font-size: 17px;
      line-height: 149px;
      float: right;
      text-align: right;
      span {
        color: #666666;
      }
      a {
        color: #d82229;
        cursor: pointer;
      }
    }
  }
  .main {
    font-size: 15px;
    width: 336px;
    height: 600px;
    margin: 0 auto;
    .div1 {
      height: 77px;
      font-size: 18px;
      color: #d82229;
      line-height: 77px;
    }
    .div2 {
      width: 762px;
      height: 45px;
      background: url(../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
      color: #999999;
      input {
        padding-left: 50px;
        width: 336px;
        height: 43px;
        border: 0;
        border: 1px solid #eaeaea;
        background: none;
        border-radius: 5px;
        float: left;
      }
      .tishi {
        float: left;
        width: 350px;
        height: 43px;
        font-size: 14px;
        align-items: center;
        .tishi-l {
          margin-left: 13px;
          color: #d82229;
          float: left;
          line-height: 46px;
        }
        .tishi-r {
          color: #d82229;
          line-height: 46px;
          float: left;
        }
      }
    }
    .mima {
      position: relative;
      background: url(../../assets/icon/mima1.png) no-repeat 13px 13px;
      margin-top: 10px;
      .p1 {
        font-size: 12px;
        position: absolute;
        bottom: -25px;
        color: #d82229;
        width: 336px;
        background: url(../../assets/icon/tishi.png) no-repeat 10px 0px;
        padding-left: 35px;
      }
    }
    .div3 {
      width: 762px;
      height: 45px;

      margin-bottom: 30px;

      .div3-1 {
        width: 336px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        float: left;
        border: 1px solid #eaeaea;
        color: #333333;
        border-radius: 5px;
        cursor: pointer;
      }
      .div3-2 {
        width: 424px;
        height: 45px;
        float: left;
        line-height: 45px;
        text-align: center;
        .div3-2-l {
          margin-left: 13px;
          color: #d82229;
          float: left;
          line-height: 46px;
        }
        .div3-2-r {
          line-height: 46px;
          float: left;
          color: #999999;
          color: #d82229;
        }
      }
    }
    .div3-y {
      width: 337px;
      height: 45px;
      margin-bottom: 30px;
      .teshu {
        width: 150px;
        height: 40px;
        border-radius: 5px;
        color: #999999;
        border: 1px solid #ccc;
        background: url(../../assets/icon/yanzhengma.png) no-repeat 13px 13px;
        float: left;
        padding-left: 54px;
      }
      .time {
        width: 117px;
        height: 40px;
        background: #d82229;
        color: #fff;
        line-height: 45px;
        text-align: center;
        float: left;
        margin-left: 12px;
        border-radius: 5px;
      }
    }
  }
  .div4 {
    height: 18px;
    position: relative;

    input:checked + label {

      background-image: url('../../assets/icon/gouxuan.png');
      background-repeat: no-repeat;
      background-position: 2px 2px;
    }
    .ipt1 {
      display: none;
      float: left;
      background: url(../../assets/icon/shoujihao1.png) no-repeat 13px 13px;
    }
    .lab1 {
      float: left;
      position: absolute;
      width: 18px;
      height: 18px;
      border: 1px solid #ccc;
      top: -2px;
    }

    .div4-2 {
      float: left;
      margin-left: 16px;
      line-height: 18px;
      font-size: 15px;
      .span1 {
        width: #666666;
        margin-left: 16px;
      }
      .span2 {
        margin-left: 7px;
        color: #d82229;
      }
    }
  }
  .regist {
    width: 284px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: #d82229;
    border: none;
    margin-top: 30px;
    font-size: 16px;
    color: #fff;
    border: 0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    &.grey {
      background: #ccc;
    }
  }
  .line {
    width: 100%;
    height: 0.1px;
    background: #d82229;
  }
  .log {
    padding: 52px 0;
    padding-left: 20%;
  }
  .border {
    border: 1px solid $color168;
  }
}
.error {
  color: #d82229 !important;
}

.error-b,.error-c,.error-d {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: flex-start;
  align-items: center;
  color: #d82229 !important;
  padding-left: 20px;
  background: url("../../assets/image/tishi.png") no-repeat 0 8px;
  font-size: 13px;
}
.correct1,.correct2,.correct3{
  height: 30px;
  visibility: hidden;
}
</style>

